import React, {useCallback, useEffect, useRef, useState} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import packageInfo from '../package.json';
import {Chart, LineController, LineElement, PointElement, RadarController, RadialLinearScale, Tooltip} from 'chart.js';
import {Button} from "antd";
import {CloseOutlined} from '@ant-design/icons';
import {KidLearningApp} from "./KidLearningApp";

const kidLearningApp = new KidLearningApp();

function App() {
    const {
        unityProvider,
        addEventListener,
        removeEventListener,
        isLoaded,
        sendMessage,
    } = useUnityContext({
        loaderUrl: "Build/Build/Build.loader.js",
        dataUrl: "Build/Build/Build.data",
        frameworkUrl: "Build/Build/Build.framework.js",
        codeUrl: "Build/Build/Build.wasm",
        streamingAssetsUrl: "Build/StreamingAssets",
    });

    // We'll use a state to store the device pixel ratio.
    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    const [showChart, setShowChart] = useState(false);
    const [chartData, setChartData] = useState<any>({
        labels: [
            'Eating',
            'Drinking',
            'Sleeping',
            'Designing',
            'Coding',
            'Cycling',
            'Running'
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [65, 59, 90, 81, 56, 55, 40],
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
        }, {
            label: 'My Second Dataset',
            data: [28, 48, 40, 19, 96, 27, 100],
            fill: true,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
        }]
    });
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);


    useEffect(() => {
        if (showChart && chartRef.current) {
            const canvas = chartRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx === null) {
                    return;
                } else {
                    const pixelRatio = window.devicePixelRatio;
                    canvas.width = canvas.clientWidth * pixelRatio;
                    canvas.height = canvas.clientHeight * pixelRatio;
                    ctx.scale(pixelRatio, pixelRatio);
                    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                    ctx.imageSmoothingEnabled = false;
                    // Register the radar chart type and radial scale
                    Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, LineController, Tooltip);
                }
            }
            // Instantiate the radar chart
            chartInstance.current = new Chart(chartRef.current!.getContext('2d')!, {
                type: 'radar',
                data: chartData,
                options: {
                    scales: {
                        r: {
                            grid: {
                                color: 'rgba(255, 255, 255, 0.1)', // faint white for grid lines
                            },
                            angleLines: {
                                color: 'rgba(255, 255, 255, 0.1)' // faint white for angle lines
                            },
                            ticks: {
                                color: 'white', // white for radial tick labels
                                backdropColor: 'transparent' // transparent backdrop for radial tick labels
                            },
                            pointLabels: {
                                color: 'yellow' // white for point labels around the perimeter
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            titleFont: {
                                size: 20, // Adjust as necessary
                                family: 'Arial', // Adjust as necessary
                            },
                            bodyFont: {
                                size: 16, // Adjust as necessary
                                family: 'Arial', // Adjust as necessary
                            }
                        },
                        legend: {
                            labels: {
                                color: 'white' // white for legend labels
                            }
                        }
                    },
                    elements: {
                        line: {
                            borderWidth: 3
                        }
                    }
                },
            });
        }

        return () => {
            // Clean up the chart instance when the component is unmounted or when the chart is hidden
            if (chartInstance.current) {
                chartInstance.current.destroy();
                chartInstance.current = null;
            }
        };
    }, [showChart, chartData]);

    const setViewHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        console.log('setViewHeight', vh);
    }

    const handleReactV2Message = useCallback(async (func: string, jsonData: string) => await kidLearningApp.HandleReactMessage(func, jsonData), []);

    function DrawChart() {
        return (
            
            <div style={{
                height: '100%',
                width: '100%',
                backgroundColor: '#333',
                position: 'relative' // Add this for the close button's positioning
            }}>
                {/* Close Button */}
                <Button
                    icon={<CloseOutlined />}
                    onClick={event => setShowChart(false)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1
                    }}
                />

                {/* Chart container */}
                <div style={{
                    height: '100%',
                    display: !showChart ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <iframe src="https://www.example.com" style={{
                        width: '80%',
                        height: '80%',
                    }}>
                    </iframe>
                    {/*<canvas ref={chartRef} style={{
                        width: '80%',
                        height: '80%',
                    }}></canvas>*/}
                </div>
            </div>
        );
    }



    useEffect(function () {
            function handleOrientationChange() {
                setIsLandscape(window.innerWidth > window.innerHeight);
            }

            // A function which will update the device pixel ratio of the Unity
            // Application to match the device pixel ratio of the browser.
            const updateDevicePixelRatio = function () {
                setDevicePixelRatio(window.devicePixelRatio);
            };
            // A media matcher which watches for changes in the device pixel ratio.
            const mediaMatcher = window.matchMedia(
                `screen and (resolution: ${devicePixelRatio}dppx)`
            );
            // Adding an event listener to the media matcher which will update the
            // device pixel ratio of the Unity Application when the device pixel
            // ratio changes.
            mediaMatcher.addEventListener("change", updateDevicePixelRatio);

            window.addEventListener('resize', setViewHeight);
            window.addEventListener('resize', handleOrientationChange);
            setViewHeight();


            if (sendMessage) kidLearningApp.sendMessage = sendMessage;
            kidLearningApp.setShowChart = setShowChart;
            kidLearningApp.setChartData = setChartData;
            addEventListener("ReactMessage", handleReactV2Message as any);

            window.addEventListener("showChart", showChart);

            function showChart() {
                setShowChart(true);
            }

            return function () {
                // Removing the event listener when the component unmounts.
                mediaMatcher.removeEventListener("change", updateDevicePixelRatio);

                window.removeEventListener('resize', setViewHeight);
                window.removeEventListener('resize', handleOrientationChange);

                window.removeEventListener("showChart", showChart);
                
                removeEventListener("ReactMessage", handleReactV2Message as any);
            };
        },
        [isLoaded]
    );

    const containerStyle = isLandscape ? {
        width: 'calc(100 * var(--vh) * 16/9)',
        height: '100%',
        overflow: 'hidden',
    } : {
        width: 'calc(100 * var(--vh) * 9/16)',
        height: '100%',
        overflow: 'hidden'
    };

    return (
        <div style={{
            background: '#202f56',
            height: 'calc(100 * var(--vh))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={containerStyle}>
                {showChart && <DrawChart/>}
                <Unity
                    unityProvider={unityProvider}
                    style={{
                        height: '100%',
                        width: '100%',
                        // hide the canvas when the iframe is shown
                        display: showChart ? 'none' : 'block',
                    }}
                    devicePixelRatio={devicePixelRatio}
                />

                <div className="version-display">
                    Version: {packageInfo.version}
                </div>
            </div>
        </div>
    );
}

export default App;