import packageInfo from "../package.json";

export class KidLearningApp {
    db: IDBDatabase | null = null;
    sendMessage?: (gameObjectName: string, methodName: string, parameter?: string | number | undefined) => void;
    setShowChart?: (value: (((prevState: boolean) => boolean) | boolean)) => void;
    setChartData?: (value: (((prevState: any) => any) | any)) => void;

    constructor() {
        // setup indexedDB
        this.setupIndexedDB();
    }

    setupIndexedDB() {
        const openRequest = indexedDB.open('kidLearningDB', 1);

        openRequest.onupgradeneeded = (event: any) => {
            this.db = event.target.result;

            if (this.db && !this.db.objectStoreNames.contains('data')) {
                this.db.createObjectStore('data');
            }
        };

        openRequest.onsuccess = (event: any) => {
            this.db = event.target.result;
            console.log("IndexedDB setup successful");
        };

        openRequest.onerror = (event: any) => {
            console.error("Error setting up IndexedDB:", event);
        };
    }

    addToDB(value: any, key?: IDBValidKey) {
        if (!this.db) return;

        const transaction = this.db.transaction("data", "readwrite");
        const store = transaction.objectStore("data");
        if (key) {
            store.put(value, key);
        } else {
            store.add(value);
        }
    }

    /**
     * Load data from the database based on a specific key.
     * @param key The unique key to search for in the database.
     * @param callback A callback function that is executed with the retrieved data.
     */
    loadFromDB(key: IDBValidKey, callback: (data: any) => void) {
        if (!this.db) return;

        console.log('loadFromDB', key);
        const transaction = this.db.transaction("data", "readonly");
        const store = transaction.objectStore("data");
        const request = store.get(key);

        request.onsuccess = () => {
            if (request.result) {
                // Call the callback with the retrieved data
                callback(request.result);
            } else {
                console.warn(`Key ${key} not found in IndexedDB.`);
                callback(undefined);
            }
        };

        request.onerror = (event: any) => {
            console.error("Error loading data from IndexedDB:", event);
        };
    }

    deleteFromDB(key: IDBValidKey) {
        if (!this.db) return;

        const transaction = this.db.transaction("data", "readwrite");
        const store = transaction.objectStore("data");
        store.delete(key);
    }

    clearStore() {
        if (!this.db) return;

        const transaction = this.db.transaction("data", "readwrite");
        const store = transaction.objectStore("data");
        store.clear();
    }

    listAllKeys(callback: (keys: IDBValidKey[]) => void) {
        if (!this.db) return;

        const transaction = this.db.transaction("data", "readonly");
        const store = transaction.objectStore("data");
        const request = store.getAllKeys();

        request.onsuccess = () => {
            // Call the callback with the retrieved keys
            callback(request.result);
        };

        request.onerror = (event: any) => {
            console.error("Error retrieving keys from IndexedDB:", event);
        };
    }

    async HandleReactMessage(func: string, jsonData: string) {
        console.log('HandleReactMessage 1', func, jsonData);
        await new Promise(resolve => setTimeout(resolve, 1));

        if (!this.sendMessage) return;

        const data = JSON.parse(jsonData);
        const dataFunc = data.func;

        const split = dataFunc.split("|*$|");
        switch (split[0]) {
            case 'unityInitialized': {
                this.sendMessage("ReactManager", "OnReactMessageReceived", JSON.stringify({
                    func: 'SetGameVersion',
                    data: JSON.stringify({
                        version: packageInfo.version
                    })
                }));
            }
                break;
            case 'setShowChart': {
                if (this.setShowChart) {
                    this.setShowChart(true);
                }
            }
                break;
            case 'setChartData': {
                if (this.setChartData) {
                    this.setChartData(data.data);
                }
                if (this.setShowChart) {
                    this.setShowChart(true);
                }
            }
                break;
            case 'saveLocalStorage': {
                // Do the saving
                // url = metadata, contains the amount of parts
                // key of the parts will be saved as url:{part}, url:1, url:2, url:3 etc...
                /*
                new
                    {
                        url,
                        currentPart = i,
                        maxParts = chunks.Count,
                        data = chunk
                    }
                 */
                const parsedData = JSON.parse(data.data);
                // If it's the last chunk, save the metadata as well
                const currentPart = parsedData.currentPart || 0;
                this.addToDB(parsedData.data, `${parsedData.url}:${currentPart}`);
                if (currentPart === parsedData.maxParts - 1) {
                    // store the metadata
                    this.addToDB({
                        maxParts: parsedData.maxParts,
                    }, parsedData.url);
                }

                this.sendMessage("ReactManager", "OnReactMessageReceived", JSON.stringify({
                    func: dataFunc,
                    data: JSON.stringify({})
                }));
            }
                break;
            case 'loadLocalStorageMetadata': {
                const parsedData = JSON.parse(data.data);
                // request the metadata of the sprite
                this.loadFromDB(parsedData.url, (result) => {
                    if (this.sendMessage)
                        this.sendMessage("ReactManager", "OnReactMessageReceived", JSON.stringify({
                            func: dataFunc,
                            data: result ? JSON.stringify({
                                url: parsedData.url,
                                maxParts: result.maxParts,
                            }) : "",
                        }));
                });
            }
                break;
            case 'loadLocalStoragePart': {
                // Send the part of the sprite
                const parsedData = JSON.parse(data.data);
                const url = parsedData.url;
                const part = parsedData.part;

                this.loadFromDB(`${url}:${part}`, (result) => {
                    if (this.sendMessage)
                        this.sendMessage("ReactManager", "OnReactMessageReceived", JSON.stringify({
                            func: dataFunc,
                            data: result ? JSON.stringify({
                                url: url,
                                part: part,
                                data: result,
                            }) : "",
                        }));
                });
            }
                break;
            case 'copyTextToClipboard': {
                // copy the text to the clipboard
                const parsedData = JSON.parse(data.data);
                const text = parsedData.text;
                navigator.clipboard.writeText(text).then(() => {
                }, (err) => {
                    console.error('Could not copy text: ', err);
                });
            }
                break;
        }
    }
}